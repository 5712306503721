import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextInfoContent from "@mui-treasury/components/content/textInfo";
import { useBlogTextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/blog";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(3), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    maxWidth: 400,
    maxHeight: 600,
    marginLeft: "auto",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: spacing(2),
    marginBottom: spacing(5),
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
    },
  },

  img: {
    verticalAlign: "middle",
    width: "300px",
    height: "300px",
    borderRadius: "10%",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    [breakpoints.up("md")]: {
      flexDirection: "row",
      width: "350px",
      height: "350px",
      borderRadius: "5%",
    },
  },
}));

export const ProfileCard = React.memo(function ProfileCard({
  imgsrc,
  subtitle,
  title,
  description,
}) {
  const styles = useStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  const shadowStyles = useOverShadowStyles();
  return (
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardContent>
        <Grid container direction="column" justify="center">
          <img src={imgsrc} className={styles.img} />
          <TextInfoContent
            classes={contentStyles}
            heading={title}
            overline={subtitle}
            body={description}
          />
        </Grid>
      </CardContent>
    </Card>
  );
});

export default ProfileCard;
