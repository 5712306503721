import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import BackgroundBanner from "../components/BackgroundBanner";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(3), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    fontFamily: "Montserrat",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
    paddingBottom: spacing(5),
    marginBottom: spacing(5),
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
    },
  },
  bold: {
    fontWeight: 600,
  },
}));

export default function PrivacyPolicy() {
  const styles = useStyles();

  const shadowStyles = useOverShadowStyles();

  return (
    <div>
      <BackgroundBanner title={"Privacy"} subtitle={"policy"} />
      <Grid container direction="row" justify="center">
        <Card className={cx(styles.root, shadowStyles.root)}>
          <ul>
            <Typography variant="h6" paragraph="true">
              This privacy policy contains details on how Ordantech Sdn. Bhd.
              (hereinafter referred to as “the company”) handles, uses and
              protects information for everyone (hereinafter collectively
              referred to as “users”) who uses our services and websites.
            </Typography>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                1. Personal Information
              </Typography>
              <p>
                In this policy “Personal Information” refers to collected
                information that could specifically identify an individual and
                may include but is not limited to name, date of birth, business
                name, address, telephone number, email address or other contact
                addresses. Other electronic personal information that may be
                collected when accessing the company’s services and websites is
                covered in section 5.
              </p>
            </li>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                2. Information Usage
              </Typography>
              <p>
                The company reserves the right to use any collected personal
                information in the following ways
              </p>
              <ul>
                <li>
                  2.1 To manage and operate Ordantech.com and Ordantech Partners
                  (hereinafter collectively referred to as our “services”).
                </li>
                <li>
                  2.2 To contact and inform users of our services and to provide
                  guidance on how to use our services.
                </li>
                <li>
                  2.3 To send questionnaires or surveys to users to obtain data
                  on improving our services and advertising.
                </li>
                <li>
                  2.4 Displaying saved personal information to allow users to
                  more easily access our services.
                </li>
                <li>
                  2.5 To improve our services, aid in planning and development
                  of new services, marketing, market research and development of
                  new business partners.
                </li>
                <li>2.6 To confirm the identity of users.</li>
                <li>
                  2.7 To bill or process payments related to services charges.
                </li>
                <li>
                  2.8 To identify and restrict access to users who violate the
                  Ordantech.com or Ordantech Partners Terms and Conditions of
                  use. Including but not restricted to non-payment, causing
                  damage to a third party or those who intend to use our
                  services for fraud.
                </li>
                <li>2.9 To more effectivity respond to users’ enquiries.</li>
                <li>
                  2.10 When users’ personal information is requested or used
                  under the terms of the Personal Information Protection Act or
                  other laws and regulations.
                </li>
                <li>2.11 To obtain consent from users.</li>
              </ul>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                3. Consent for Providing Personal Information to Third Parties
              </Typography>
              <p>
                While using the company’s services, you agree in advance that
                the company may provide personal information to third parties as
                specified in the following items
              </p>
              <ul>
                <li>
                  3.1 To entrust secure storage of personal information and
                  other data to a cloud server (including servers located in a
                  foreign country) operated by an external third party.
                </li>
                <li>
                  3.2 If the company receives a disclosure request and deems it
                  valid, a user’s personal information may be disclosed to a
                  relevant law enforcement agency.
                </li>
                <li>
                  3.3 In cases where the user is a minor and personal
                  information is requested by the user’s legal guardians or
                  legal representative.
                </li>
              </ul>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                4. Providing Personal Information to Third Parties Without
                Consent
              </Typography>
              <p>
                The company may also provide personal information to third
                parties without seeking consent of the user as specified in the
                following items
              </p>
              <ul>
                <li>
                  4.1 In cases where disclosure of personal information is
                  required by law enforcement agencies or court judgments
                  related to violations of the law or other regulations.
                </li>
                <li>
                  4.2 In cases where it is necessary for the protection of human
                  life, body or property, and it is difficult to obtain the
                  consent of the user.
                </li>
                <li>
                  4.3 If required under the Personal Information Protection Act
                  or other laws and regulations.
                </li>
              </ul>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                5. Electronic Data and Tracking
              </Typography>
              <p>
                In this policy “Electronic Data” refers to the personal
                information gathered when users browse web pages of the services
                operated by the company. This includes but is not limited to –
                the browsing history of each user, user’s advertisement display
                history, user’s keyword searches, user’s time zone, user’s
                browsing device and software, user’s IP address, user’s cookie
                information, user’s scrolling and mouse pointer tracking.
              </p>
              <ul>
                <li>
                  5.1 The company may use the gathered electronic data to
                  <ul>
                    <li>
                      5.1.1 Analyze and record the number of users and traffic
                      on each service to aid in marketing and market research.
                    </li>
                    <li>
                      5.1.2 Improve each service, or plan and develop new
                      services.
                    </li>
                  </ul>
                </li>
                <li>
                  5.2 The company may task advertising networks with the display
                  of advertising related to the company’s services. Users
                  accessing the services via these advertisements may receive a
                  cookie on their browsing device which contains information
                  such as an anonymous ID or advertisement identifier. This
                  cookie may be subject to the privacy policy of the advertising
                  network as well as the company’s privacy policy. Please check
                  each individual advertising network for more information on
                  their privacy policies.
                </li>
              </ul>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                6. Disclosure of Personal Information
              </Typography>
              <p>
                When the company receives a request under the Personal
                Information Protection Act to disclose personal information, the
                company will promptly confirm if the request is genuine and
                lawful, then disclose the personal information and notify the
                user a disclosure has been processed. However, if the company
                receives a disclosure request which the company is not obligated
                under law to process, the company will not disclose the
                information and will inform the user of the denied request.
              </p>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                7. Correction of Personal Information
              </Typography>
              <p>
                In the event there is a request for a correction, addition,
                suspension or deletion (hereinafter collectively referred to as
                “correction”) of personal information from either a user or a
                third party, we will endeavor to investigate the request and if
                confirmed valid, make the correction after confirming the
                identity of the user or third party. In cases where the
                correction is related to stored personal information that is not
                factual or has been handled inappropriately the company will
                respond promptly to the correction. The company may deny
                correction requests if processing the correction would result in
                violations of the law or other regulations, the user or third
                party requesting the correction will then be notified of the
                denial.
              </p>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                8. Management of Personal Information
              </Typography>
              <p>
                The company is responsible for managing access to personal
                information, enacting measures to prevent unauthorized access to
                personal information, prevention of data loss or damage to
                personal information and other personal information safety
                management The company will take the necessary and appropriate
                measures to achieve these tasks.
              </p>
              <p>
                When the company entrusts the provision of our service to a
                third party, the company will select outsourcers that are deemed
                to handle personal information properly, and in the contract
                with the third party, will establish safety management measures,
                ensuring confidentiality of personal information. The company
                shall properly determine matters concerning the handling of
                personal information and shall conduct necessary and proper
                supervision.
              </p>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                9. Changes to this Policy
              </Typography>
              <p>
                When the company changes the contents of this policy, the
                company will notify users in advance of the changes. After the
                deadline specified in the notification, the policy changes will
                be automatically applied.
              </p>
            </li>
            <p></p>
            <li>
              <Typography paragraph="true" className={styles.bold}>
                10. Questions Regarding Handling of Personal Information
              </Typography>
              <p>
                If you have any questions or concerns related to the handling of
                personal information please contact the company via the contact
                details at our contact section.
              </p>
            </li>
          </ul>
        </Card>
      </Grid>
    </div>
  );
}
