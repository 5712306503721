import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import surveyimg from "../assets/img/svg/survey.svg";
import wireframeimg from "../assets/img/svg/wireframe.svg";
import codingimg from "../assets/img/svg/coding.svg";
import prodimg from "../assets/img/svg/prod.svg";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CreateIcon from "@material-ui/icons/Create";
import CodeIcon from "@material-ui/icons/Code";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import TimelineDot from "@material-ui/lab/TimelineDot";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  paper: {
    padding: "6px 16px",
    margin: "auto",
    borderRadius: spacing(3), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 200px rgba(34, 35, 58, 0.2)",
    maxWidth: 300,
  },

  img: {
    verticalAlign: "middle",
    width: "100%",
    height: "100px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
      width: "300px",
      height: "300px",
    },
  },
}));

const timeLineInfos = [
  {
    subtitle: "Initial Stage",
    title: "Inquiry",
    description: `Processing of inquiries received from customer and laying out the
    proposal`,
    imgsrc: surveyimg,
    icon: <AssignmentIcon />,
    line: <TimelineConnector />,
  },
  {
    subtitle: "Proposal Stage",
    title: "Wireframing",
    description: `Designing web/app layout by wireframing the process`,
    imgsrc: wireframeimg,
    icon: <CreateIcon />,
    line: <TimelineConnector />,
  },
  {
    subtitle: "Development Stage",
    title: "Programming & Coding",
    description: `Start developing on both front/back-end frameworks`,
    imgsrc: codingimg,
    icon: <CodeIcon />,
    line: <TimelineConnector />,
  },
  {
    subtitle: "Deployment Stage",
    title: "Production",
    description: `Ready to deploy the app/website into production`,
    imgsrc: prodimg,
    icon: <DynamicFeedIcon />,
    line: "", //no connector on last line
  },
];

export default function Workflow() {
  const classes = useStyles();

  return (
    <Timeline align="alternate">
      {timeLineInfos.map((timeLineInfo, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {timeLineInfo.subtitle}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              {timeLineInfo.icon}
            </TimelineDot>
            {timeLineInfo.line}
          </TimelineSeparator>
          <TimelineContent>
            <Paper
              elevation={3}
              className={classes.paper}
              data-aos="zoom-in-down"
            >
              <Typography
                variant="h6"
                component="h1"
                style={{ fontWeight: "bold" }}
                align="center"
              >
                {timeLineInfo.title}
              </Typography>
              <img src={timeLineInfo.imgsrc} alt="" className={classes.img} />
              <Typography variant="subtitle1" align="center">
                {timeLineInfo.description}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
