import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import BackgroundBanner from "../components/BackgroundBanner";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(3), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    fontFamily: "Montserrat",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
    paddingBottom: spacing(5),
    marginBottom: spacing(5),
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
    },
  },
  bold: {
    fontWeight: 600,
  },
}));

export default function Contact() {
  const styles = useStyles();

  const shadowStyles = useOverShadowStyles();

  return (
    <div>
      <BackgroundBanner title={"Contact"} subtitle={"form"} />
      <Grid container direction="column" justify="center">
        <Card className={cx(styles.root, shadowStyles.root)}>
          <form name="contact" method="post">
            <Typography
              variant="h5"
              align="center"
              paragraph="true"
              style={{ fontWeight: "bold" }}
            >
              We'll contact you back within 2-3 business days!
            </Typography>
            <input type="hidden" name="form-name" value="contact" />
            <TextField
              type="text"
              name="name"
              id="filled-full-width"
              label="Name"
              style={{ margin: 8, marginTop: 20 }}
              placeholder="Your name"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              required
            />
            <TextField
              type="email"
              name="email"
              id="filled-full-width"
              label="Email"
              style={{ margin: 8 }}
              placeholder="Your email address"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              required
            />
            <TextField
              name="message"
              id="filled-full-width"
              label="Message"
              style={{ margin: 8 }}
              multiline
              rows={20}
              placeholder="Your message here"
              variant="outlined"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              required
            />
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{ justifyContent: "center", marginTop: "20px" }}
            >
              Submit
            </Button>
          </form>
        </Card>
      </Grid>
    </div>
  );
}

{
  /* <FormLabel htmlFor={"newsletter"} style={{ fontSize: "12px" }}>
E-mail Address :
</FormLabel>
<EmailSubscribe
onSubmit={(email) => alert(`Your email is ${email}.`)}
useStyles={useReadyEmailSubscribeStyles}
inputClearedAfterSubmit
>
<EmailTextInput
  id={"newsletter"}
  placeholder="Enter your email"
  style={{
    fontFamily: "Montserrat",
  }}
/>
<SubmitButton
  aria-label="subscribe"
  style={{
    backgroundColor: "#F5F5F5",
    color: "#F44336",
    fontFamily: "Montserrat",
  }}
>
  <Send />
</SubmitButton>
</EmailSubscribe>
<FormLabel htmlFor={"newsletter"} style={{ fontSize: "12px" }}>
E-mail Address :
</FormLabel>
<EmailSubscribe
onSubmit={(email) => alert(`Your email is ${email}.`)}
useStyles={useReadyEmailSubscribeStyles}
inputClearedAfterSubmit
>
<EmailTextInput
  id={"newsletter"}
  placeholder="Enter your email"
  style={{
    fontFamily: "Montserrat",
  }}
/>
<SubmitButton
  aria-label="subscribe"
  style={{
    backgroundColor: "#F5F5F5",
    color: "#F44336",
    fontFamily: "Montserrat",
  }}
>
  <Send />
</SubmitButton>
</EmailSubscribe>
<FormLabel htmlFor={"newsletter"} style={{ fontSize: "12px" }}>
E-mail Address :
</FormLabel>
<EmailSubscribe
onSubmit={(email) => alert(`Your email is ${email}.`)}
useStyles={useReadyEmailSubscribeStyles}
inputClearedAfterSubmit
>
<EmailTextInput
  id={"newsletter"}
  placeholder="Enter your email"
  style={{
    fontFamily: "Montserrat",
  }}
/>
<SubmitButton
  aria-label="subscribe"
  style={{
    backgroundColor: "#F5F5F5",
    color: "#F44336",
    fontFamily: "Montserrat",
  }}
>
  <Send />
</SubmitButton>
</EmailSubscribe> */
}
