import React from "react";
import { Grid } from "@material-ui/core";
import MainCard from "../components/MainCard";
import JustCard from "../components/JustCard";
import ProfileCard from "../components/ProfileCard";
import BackgroundBanner from "../components/BackgroundBanner";
import Particles from "react-tsparticles";
import "pathseg";

export default function Company() {
  return (
    <div>
      {/* <Particles
        style={{
          position: "fixed",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
        }}
        id="tsparticles"
        options={{
          background: {
            color: {
              value: "#fff",
            },
          },
          detectRetina: false,
          fpsLimit: 60,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onHover: {
                enable: false,
                mode: "bubble",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 40,
                duration: 2,
                opacity: 8,
                size: 6,
                speed: 3,
              },
            },
          },
          particles: {
            color: {
              value: "#F44336",
              animation: {
                enable: true,
                speed: 20,
                sync: true,
              },
            },
            lineLinked: {
              blink: false,
              color: "#F44336",
              consent: false,
              distance: 30,
              enable: true,
              opacity: 0.3,
              width: 0.5,
            },
            move: {
              attract: {
                enable: false,
                rotate: {
                  x: 600,
                  y: 1200,
                },
              },
              bounce: false,
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: false,
                area: 1000,
              },
              limit: 0,
              value: 200,
            },
            opacity: {
              animation: {
                enable: true,
                minimumValue: 0.05,
                speed: 1,
                sync: false,
              },
              random: false,
              value: 1,
            },
            shape: {
              type: "circle",
            },
            size: {
              animation: {
                enable: false,
                minimumValue: 0.1,
                speed: 40,
                sync: false,
              },
              random: true,
              value: 1,
            },
          },
          polygon: {
            draw: {
              enable: true,
              lineColor: "rgba(255,255,255,0.2)",
              lineWidth: 0.3,
            },
            move: {
              radius: 10,
            },
            inlineArrangement: "equidistant",
            scale: 0.3,
            type: "inline",
            url:
              "https://raw.githubusercontent.com/gist/asralf90/22ed5c73043e9e2f4c7339de30fbc2fa/raw/8b7db616f49376f40d46c75ab13e95ab773d79d6/polygonmask2.svg",
          },
        }}
      /> */}
      <BackgroundBanner title={"Profile @"} subtitle={"ordantech"} />
      <Grid container direction="row" justify="center">
        <MainCard
          title={"ABOUT US"}
          subtitle={"Incorporated in January 8,2020"}
          description={
            "Ordantech is a tech-kinda startup company. We specializes in web and mobile technologies (React-JavaScript). We always try to implement the latest technology and offer it to our customers."
          }
        />
      </Grid>

      <Grid container direction="column" justify="center" data-aos="fade-right">
        <BackgroundBanner title={"Vision &"} subtitle={"Mission"} />
        <JustCard
          title={"What's our plan?"}
          description={`While it is known worldwide that a gig economy is powered by the freelancing industry,
            we want to change the whole definition of a gig economy. 
            A true gig economy should not only consist of freelancers but comprise of part-time workers, 
            sub-contractors, small business owners, students, interns, private companies and government
            agencies who should work hand in hand to stimulate a healthy cycle of economical benefits for
            all Malaysians.There are an abundance of skilled and creative workers in Malaysia but hardly any functional
            and integrated platform to cater to the local business’s needs. It is such an arduous process to go through
            every freelancing website to find the right person for the job in a particular amount of time while having a limited budget.
            Our goal is to include part-time workers into the gig economy which normally requires physical labour/work and high communication skills.
            In addition, we want to create a more transparent, honest, easy-to-use job search platform for locals to portray their abilities
            and skills that they have for the betterment of the local economy.  We want to achieve this with our upcoming project - Sarang.
            So stay tuned for more updates!`}
        />
      </Grid>

      <Grid container direction="row" justify="center" data-aos="fade-right">
        <BackgroundBanner title={"Board"} subtitle={"MEMBERS"} />
        <Grid container direction="row" justify="center" alignContent="center">
          <ProfileCard
            imgsrc={
              "https://cdn6.f-cdn.com/ppic/71371488/logo/10304981/PRhH7/profile_logo_.JPG"
            }
            title={"Danial Asraf"}
            subtitle={"Founder & CEO"}
            description={
              "Always been fascinated with technologies especially in relations to the latest web technologies. Founded Ordantech to spur the IT developments in Malaysia after working for more than 10 years in Japan.  A self-taught programmer and developer."
            }
          />
          <ProfileCard
            imgsrc={
              "https://www.oneworldplayproject.com/wp-content/uploads/2016/03/avatar-1024x1024.jpg"
            }
            title={"Norbee Noor"}
            subtitle={"Managing Director"}
            description={
              "Initial co-founder of Ordantech. Also the current CEO of Acenn and has more than 30 years of experiences in the industry."
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
