import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Particles from "react-tsparticles";
import polygonmasklogo from "./polygonmask2.svg";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100vw",
    //height: "100vh",
    backgroundColor: "transparent",
    color: "#000",
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  bannerButton: {
    backgroundColor: "#F44336",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#000",
      color: "#FFF",
    },
  },
}));

export default function BackgroundBanner({ title, subtitle }) {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid direction="row" justify="center" alignItems="center">
        <Typography color="inherit" variant="h3">
          {title}
        </Typography>
        <Button className={classes.bannerButton}>
          <Typography color="inherit" variant="h3">
            {subtitle}
          </Typography>
        </Button>
      </Grid>
    </Container>
  );
}
