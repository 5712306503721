import React from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Banner from "../components/Banner";
import BackgroundBanner from "../components/BackgroundBanner";
import SimpleCard from "../components/SimpleCard";
import Workflow from "../components/Workflow";
import JustCard from "../components/JustCard";
import mobileappimg from "../assets/img/svg/mobileapp.svg";
import webeappimg from "../assets/img/svg/web.svg";
import uiuximg from "../assets/img/svg/uiux.svg";

const cardInfos = [
  {
    imagesource: mobileappimg,
    title: `Mobile App Development`,
    descr: `We can offer a custom-built mobile application in both Android and iOS platforms`,
  },
  {
    imagesource: webeappimg,
    title: `Web Development`,
    descr: `Web development can be built from the ground up quickly and efficiently using the latest
  web technologies the industry can offer`,
  },
  {
    imagesource: uiuximg,
    title: `UI/UX`,
    descr: `We also specializes in creating a seamless UI/UX integration with either mobile or web application`,
  },
];

export default function Home() {
  return (
    <div>
      <Banner />

      <Grid container direction="row" justify="center">
        {cardInfos.map((cardInfo, index) => (
          <SimpleCard
            key={index}
            imgsrc={cardInfo.imagesource}
            title={cardInfo.title}
            description={cardInfo.descr}
          />
        ))}
      </Grid>

      <Grid
        container
        direction="column"
        justify="center"
        data-aos="zoom-out-up"
      >
        <BackgroundBanner title={"Development"} subtitle={"workflow"} />
        <Workflow />
      </Grid>

      <Grid
        container
        direction="column"
        justify="center"
        data-aos="zoom-out-up"
      >
        <BackgroundBanner title={"Any"} subtitle={"inquiries?"} />
        <JustCard
          title={"Just shoot us an inquiry about your dream app or website!"}
          description={`Send us more details and we can discuss further more`}
          custom={
            <Box textAlign="center">
              <Button
                color="secondary"
                variant="contained"
                href="/contact"
                style={{ justifyContent: "center", marginTop: "20px" }}
              >
                Contact us
              </Button>
            </Box>
          }
        />
      </Grid>
    </div>
  );
}
