import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { ColumnToRow, Row, Item } from "@mui-treasury/components/flex";
import { NavMenu, NavItem } from "@mui-treasury/components/menu/navigation";
import { NavLink } from "react-router-dom";
import {
  SocialProvider,
  SocialLink,
} from "@mui-treasury/components/socialLink";

import { useMoonSocialLinkStyles } from "@mui-treasury/styles/socialLink/moon";
import { usePlainNavigationMenuStyles } from "@mui-treasury/styles/navigationMenu/plain";
import logo from "./polygonmask2.svg";

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
  legalLink: {
    ...typography.caption,
    justifyContent: "center",
    color:
      palette.type === "dark"
        ? "rgba(255,255,255,0.57)"
        : palette.text.secondary,
    position: "relative",
    [breakpoints.up("sm")]: {
      "&:not(:first-of-type)": {
        "&:before": {
          content: '"|"',
          display: "block",
          position: "absolute",
          left: 0,
        },
      },
    },
  },
  navMenu: {
    flexWrap: "wrap",
    fontFamily: `"Montserrat","Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  logo: {
    height: 75,
  },
}));

export const Footer = React.memo(function NeptuneAppFooter() {
  const classes = useStyles();
  return (
    <Box bgcolor={"background.paper"} width={"100%"}>
      <Container>
        <Box pt={8} pb={2}>
          <Row wrap>
            <Item grow={2}>
              <Row alignItems={"center"}>
                <Item>
                  <NavLink to="/">
                    <img
                      src={logo}
                      className={classes.logo}
                      alt={"ordantech"}
                    ></img>
                  </NavLink>
                </Item>
                <Item>
                  <Typography variant={"h6"} color={"textSecondary"}>
                    ordantech
                  </Typography>
                </Item>
              </Row>
              <NavMenu className={classes.navMenu}>
                <NavItem href={"/about"}>About</NavItem>
                <NavItem href={"/contact"}>Contact</NavItem>
                {/* <NavItem>Press</NavItem>
                <NavItem>Customer Services</NavItem> */}
              </NavMenu>
            </Item>
            <Item grow maxWidth={500} mx={"auto"}>
              <Box textAlign={"center"} mt={{ xs: 2, md: 0 }} my={2}>
                <SocialProvider useStyles={useMoonSocialLinkStyles}>
                  {/* <SocialLink
                    brand={"FacebookCircle"}
                    href={"https://www.facebook.com/"}
                  /> */}
                  <SocialLink
                    brand={"Twitter"}
                    href={"https://twitter.com/danial_oppa"}
                  />
                  <SocialLink
                    brand={"Instagram"}
                    href={"https://www.instagram.com/danial_asraf/"}
                  />
                </SocialProvider>
              </Box>
              {/* <FormLabel htmlFor={"newsletter"} style={{ fontSize: "12px" }}>
                Newsletter
              </FormLabel>
              <EmailSubscribe
                onSubmit={(email) => alert(`Your email is ${email}.`)}
                useStyles={useReadyEmailSubscribeStyles}
                inputClearedAfterSubmit
              >
                <EmailTextInput
                  id={"newsletter"}
                  placeholder="Enter your email"
                  style={{
                    fontFamily: "Montserrat",
                  }}
                />
                <SubmitButton
                  aria-label="subscribe"
                  style={{
                    backgroundColor: "#F5F5F5",
                    color: "#F44336",
                    fontFamily: "Montserrat",
                  }}
                >
                  <Send />
                </SubmitButton>
              </EmailSubscribe> */}
            </Item>
          </Row>
        </Box>
        <Divider />
        <Box pt={2} pb={10}>
          <ColumnToRow
            at={"md"}
            columnStyle={{ alignItems: "center" }}
            rowStyle={{ alignItems: "unset" }}
          >
            <Item grow ml={-2} shrink={0}>
              <NavMenu useStyles={usePlainNavigationMenuStyles}>
                <ColumnToRow at={"sm"}>
                  {/* <NavItem className={cx(classes.legalLink)}>
                    Terms & Conditions
                  </NavItem> */}
                  <NavItem
                    className={cx(classes.legalLink)}
                    href={"/privacy-policy"}
                  >
                    Privacy Policy
                  </NavItem>
                </ColumnToRow>
              </NavMenu>
            </Item>
            <Item>
              <Box py={1} textAlign={{ xs: "center", md: "right" }}>
                <Typography
                  component={"p"}
                  variant={"caption"}
                  color={"textSecondary"}
                >
                  Designed by Ordantech
                </Typography>
              </Box>
            </Item>
          </ColumnToRow>
        </Box>
      </Container>
    </Box>
  );
});

export default Footer;
