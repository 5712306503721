import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(3), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 200px rgba(34, 35, 58, 0.2)",
    position: "relative",
    maxWidth: 400,
    maxHeight: 500,
    marginLeft: "auto",
    overflow: "initial",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: spacing(2),
    marginBottom: spacing(5),
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: "initial",
  },
  img: {
    verticalAlign: "middle",
    width: "100%",
    height: "250px",
    borderRadius: "10%",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    [breakpoints.up("md")]: {
      flexDirection: "row",
      width: "350px",
      height: "350px",
      borderRadius: "5%",
    },
  },
}));

export const SimpleCard = React.memo(function SimpleCard({
  imgsrc,
  title,
  description,
}) {
  const styles = useStyles();
  const shadowStyles = useOverShadowStyles();
  return (
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardContent>
        <Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <img src={imgsrc} className={styles.img} />
        <Typography variant="subtitle1" align="center">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
});

export default SimpleCard;
