import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Particles from "react-tsparticles";
import polygonmasklogo from "./polygonmask2.svg";
import { Helmet } from "react-helmet";
import "pathseg";

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100vw",
    //height: "100vh",
    backgroundColor: "transparent",
    color: "#000",
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  bannerButton: {
    backgroundColor: "#000",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#F44336",
      color: "#FFF",
    },
  },
}));

export default function Banner() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Particles
        style={{ position: "absolute", zIndex: "-1", top: "0", left: "0" }}
        height="40%"
        width="95%"
        id="tsparticles"
        options={{
          background: {
            color: {
              value: "#fff",
            },
          },
          fpsLimit: 60,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 20,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#000",
            },
            links: {
              color: "#000",
              distance: 200,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 100,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />

      <Grid direction="row" justify="center" alignItems="center">
        <Typography color="inherit" variant="h3">
          your gateway to
        </Typography>
        <Button className={classes.bannerButton}>
          <Typography color="inherit" variant="h3">
            the digital world
          </Typography>
        </Button>
      </Grid>
    </Container>
  );
}
